
import {
  defineComponent, onMounted, ref,
} from 'vue';
import api from '@/api';

export default defineComponent({
  name: 'PayerAddBusinessType',
  setup() {
    const businessTypes = ref<{name: string | undefined, value: number | undefined}[] | undefined>();
    const selectedBusinessType = ref({
      id: 0,
      value: '',
    });

    onMounted(async () => {
      try {
        const resp = await api.businessType.findAll();
        businessTypes.value = resp.data.map((item) => ({ name: item.value, value: item.id }));
      } catch (e) {
        throw new Error('Error onMounted fetch activityType in PayerAddActivityType component');
      }
    });

    return { selectedBusinessType, businessTypes };
  },
});
