
import {
  defineComponent, reactive, ref,
} from 'vue';
import { Address } from '@/types/ObjectOfTrade';

export default defineComponent({
  name: 'PayerAddAddress',
  setup() {
    const inputValue = ref('');
    const address: Address = reactive<Address>({
      area: null,
      corpus: null,
      country: '',
      element: null,
      id: 0,
      index: null,
      locality: null,
      num: null,
      point: '',
      postcode: 0,
      region: null,
      status: '',
      userInput: null,
      yandexAddressFull: '',
    });

    function init() {
      // eslint-disable-next-line no-new
      new window.ymaps.SuggestView('suggest');
    }

    window.ymaps.ready(init);

    return { address, inputValue };
  },
});
