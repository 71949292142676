
import { defineComponent, reactive } from 'vue';
import { TaxPayer } from '@/types/ObjectOfTrade';
import Container from '@/components/Container.vue';
import PayerAddActivityType from '@/views/registry/tax-payers/components/new/PayerAddActivityType.vue';
import ContainerHeader from '@/components/ContainerHeader.vue';
import PayerAddAddress from '@/views/registry/tax-payers/components/new/PayerAddAddress.vue';
import PayerAddBusinessType from '@/views/registry/tax-payers/components/new/PayerAddBusinessType.vue';
import nalogPayer from '@/blankTypes/nalogPayer';

export default defineComponent({
  name: 'TaxPayerNew',
  components: {
    PayerAddBusinessType,
    PayerAddAddress,
    ContainerHeader,
    PayerAddActivityType,
    Container,
  },
  setup() {
    const taxPayer = reactive<TaxPayer>(nalogPayer);

    return { taxPayer };
  },
});
