import { TaxPayer } from '@/types/ObjectOfTrade';

const taxPayer: TaxPayer = {
  activityType: null,
  jurName: null,
  phoneNumbers: null,
  workersCount: null,
  area: null,
  address: {
    id: null,
    status: null,
    country: null,
    region: null,
    locality: null,
    area: null,
    postcode: null,
    element: null,
    num: null,
    index: null,
    corpus: null,
    point: null,
    yandexAddressFull: null,
    userInput: null,
  },
  businessType: null,
  gced: '',
  id: 0,
  inn: '',
  saleType: '',
  objectType: '',
  inspector: null,
  kkmBso: false,
  kkmCount: 0,
  nalogRegime: null,
  nds: null,
  nsp: null,
  objectName: '',
  objectStatus: '',
  status: '',
  subBusinessName: '',
  tradeObject: null,
  ugnsTno: null,
  kkmModel: null,
  photo: null,
  createTimeStamp: '',
  delegateFrom: null,
  delegateDate: '',
  objectStatusInstaller: null,
  objectStatusChangeRegime: '',
  objectStatusDate: '',
  objectStatusIsLiquidated: false,
  objectStatusLiquidatedInfo: {
    fio: '',
    lastChangeDate: '',
  },
  createDate: '',
  isRent: null,
  rentAmount: null,
};

export default taxPayer;
