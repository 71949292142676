
import {
  defineComponent, onMounted, ref,
} from 'vue';
import api from '@/api';

export default defineComponent({
  name: 'PayerAddActivityType',
  setup() {
    const activityTypes = ref<{name: string | undefined, value: number | undefined}[] | undefined>();
    const selectedActivityType = ref({
      id: 0,
      value: '',
    });

    onMounted(async () => {
      try {
        const resp = await api.activityType.findAll();
        activityTypes.value = resp.data.map((item) => ({ name: item.value, value: item.id }));
      } catch (e) {
        throw new Error('Error onMounted fetch activityType in PayerAddActivityType component');
      }
    });

    return { selectedActivityType, activityTypes };
  },
});
